import { point } from '@turf/helpers'
import destination from '@turf/destination'
import { getCoord } from '@turf/invariant'

export function bboxByDiscance (coords, kmdistance) {
  let point = coordsToPoint(coords)
  let sw = getCoord(destination(point, kmdistance, 135, {units: 'kilometers'}))
  let ne = getCoord(destination(point, kmdistance, -45, {units: 'kilometers'}))
  return [sw[0], sw[1], ne[0], ne[1]]
}

export function coordsToPoint (coords, properties) {
  const {lat, lng} = coords
  if (!(Number.isFinite(lat) && Number.isFinite(lng) && lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180)) {
    throw new Error(`invalid coordinates: ${coords}`)
  }
  return point([lng, lat], properties)
}
