const formatcoords = require('formatcoords')
const _ = require('lodash')

export function format (coords) {
  if (_.isEmpty(coords)) {
    return ''
  }
  let {lat, lng} = coords
  const ret = formatcoords(lat, lng).format('d X', {latLonSeparator: ', '})
  if (/NaN/.test(ret)) {
    return ''
  }
  return ret
}

// https://github.com/servant-of-god/coordinate-parser
// https://github.com/gmaclennan/parse-dms

// 41.5 -81.0
// 41.5, -81.0
// 41.5 N -81.0 W
// N 41.5 W -81.0
// -41.5 S;81.0 E
// 41.5;-81.0
// 41.5,-81.0
// 64.03484° N 44.13435° E
// 23 26' 22" N 23 27' 30" E
// 23 26m 22s N 23 27m 30s E
// UT: N 39°20' 0'' / W 74°35' 0''
// 41.40338, 2.17403 // {lat: 41.40338, lng: 2.17403}
// 41°24'12.2"N 2°10'26.5"E // {lat: 41.40339, lng: 2.17403}
// 31T 430959.5858286716 4583866.770942634 // {lat: 41.40338, lng: 2.17403}

// the following code is from https://github.com/danielsiwiec/parse-coords

class RegexBuilder {
  constructor () {
    this.string = '^'
  }

  plusMinus () {
    this.string += '[-+]?'
    return this
  }

  decimalNumber () {
    this.string += '\\d+(\\.\\d+)?'
    return this
  }

  integer (digits) {
    this.string += `\\d${digits ? `{${digits}}` : '+'}`
    return this
  }

  comma () {
    this.string += ',?\\s*'
    return this
  }

  degreeOption () {
    this.string += '(°|° | )'
    return this
  }

  minuteOption () {
    this.string += `'?`
    return this
  }

  secondOption () {
    this.string += `"?`
    return this
  }

  whiteSpace () {
    this.string += '\\s*'
    return this
  }

  with (string) {
    this.string += string
    return this
  }

  // regex: /^\d+°\d+'\d+(\.\d+)?"\s*[NS]\s*\d+°\d+'\d+(\.\d+)?"\s*[EW]$/,
  // regex: /^\d{2}[C-X] \d+(\.\d+)? \d+(\.\d+)?$/,

  build () {
    this.string += '$'
    return new RegExp(this.string)
  }
}

const parsers = [
  {
    regex: new RegexBuilder()
      .plusMinus().decimalNumber()
      .comma()
      .plusMinus().decimalNumber()
      .build(),
    parser: fromDecimalDegrees
  },
  {
    regex: new RegexBuilder()
      .plusMinus().integer().degreeOption().decimalNumber().minuteOption()
      .comma()
      .plusMinus().integer().degreeOption().decimalNumber().minuteOption()
      .build(),
    parser: fromDecimalMinutes
  },
  {
    regex: new RegexBuilder()
      .integer().degreeOption().integer().minuteOption().decimalNumber().secondOption().whiteSpace().with('[NS]')
      .whiteSpace()
      .integer().degreeOption().integer().minuteOption().decimalNumber().secondOption().whiteSpace().with('[EW]')
      .build(),
    parser: fromMinutesSeconds
  }
]

export function parse (input) {
  if (!input) {
    return ''
  }
  let { parser } = parsers.find(({ regex }) => input.match(regex)) || { parser: () => undefined }
  return parser(input)
}

function fromDecimalDegrees (input) {
  let coordinates = input.replace(',', ' ').replace(/\s+/g, ' ').split(' ')
  return {
    lat: parseFloat(coordinates[0]),
    lng: parseFloat(coordinates[1])
  }
}

function fromDecimalMinutes (input) {
  let coordinates = input.replace(',', ' ').replace(/°/g, ' ').replace(/\s+/g, ' ').split(' ')
  let lat = [coordinates[0], coordinates[1]]
  let lng = [coordinates[2], coordinates[3]]

  function getSign (input) {
    return parseFloat(input) > 0 ? 1 : -1
  }

  return {
    lat: parseFloat(lat[0]) + getSign(lat[0]) * parseFloat(lat[1]) / 60,
    lng: parseFloat(lng[0]) + getSign(lng[0]) * parseFloat(lng[1]) / 60
  }
}

function fromMinutesSeconds (input) {
  // remove spaces before NSEW
  let coordinates = input.replace(/\s(\D)/g, '$1').split(' ')
  let lat = coordinates[0].trim().match(/^(\d+)°(\d+)'(\d+(\.\d+))?"([NS])/)
  let lng = coordinates[1].trim().match(/^(\d+)°(\d+)'(\d+(\.\d+))?"([EW])/)

  let toDecimal = function (minutes, seconds) {
    let number = (parseFloat(minutes) * 60 + parseFloat(seconds)) / 3600
    // + to convert string to a number
    return +(number.toFixed(5))
  }

  let getSign = function (direction) {
    return direction === 'N' || direction === 'E' ? 1 : -1
  }

  return {
    lat: getSign(lat[5]) * (parseFloat(lat[1]) + toDecimal(lat[2], lat[3])),
    lng: getSign(lng[5]) * (parseFloat(lng[1]) + toDecimal(lng[2], lng[3]))
  }
}
