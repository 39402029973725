import React from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'

// Components
import { graphql } from 'gatsby'
import { LayoutWithoutSidebar } from '../components/layout.jsx'


import { initMap } from '../lib/geo/map.js'
import { parse, format } from '../lib/geo/format.js'
import { bboxByDiscance } from '../lib/geo/helper.js'
import { wd } from '../lib/wikidata.js'

const _ = require('lodash')

// switch style: https://docs.mapbox.com/mapbox-gl-js/example/setstyle/
// map.setStyle('mapbox://styles/mapbox/' + layerId + '-v9');

// https://docs.mapbox.com/mapbox-gl-js/example/mouse-position/
// map.on('mousemove', function (e) {
// JSON.stringify(e.lngLat);



export default class GeohackPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // mapbox is patched during build time so wait until runtime to initialize
      detailsRequested: false,
      trackInfo: {},
      trackGeoJson: {},
      wikiGeoJson: {},
      coords: '',
      radius: 25, // km
    }
  }

  componentDidMount() {
    this.map = initMap(this.mapContainer, () => {
      this.map.on('dblclick', (e) => {
        let coords = {lng: e.lngLat.lng, lat: e.lngLat.lat}
        this.refocusMap(coords)
        this.setState({coords: coords})
      })

      // When a click event occurs on a feature in the wikiLayer layer, open a popup at the
      // location of the feature, with description HTML from its properties.
      this.map.on('click', 'wikiLayer', (e) => {
        console.log('click', e)
        var coordinates = e.features[0].geometry.coordinates.slice()
        let p = e.features[0].properties
        var description = `${p.name} ${!!p.art ? '('+p.art+')': ''}<br/>
        <a href="https://www.wikidata.org/wiki/${p.wikidata}">${p.wikidata}</a>`
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map)
        })

    // decode location from URL
      const urlParams = new URLSearchParams(window.location.search)
      const r = Number(urlParams.get('r'))
      if(_.isFinite(r) && r > 0) {
        this.setState({radius: r})
      }
      const coords = parse(urlParams.get('q'))
      if (coords != '') {
        this.refocusMap(coords)
        this.setState({coords: coords})
      }
    })
  }

  componentWillUnmount() {
    this.map.remove()
  }

  refocusMap = (coords) => {
    // calculate the zoom level we need to display this.state.radius
    let bbox = bboxByDiscance(coords, this.state.radius)
    console.log(bbox, this.state.radius)
    this.map.fitBounds(bbox, { padding: 30 })
    window.history.replaceState(null, null, `?q=${coords.lat},${coords.lng}&r=${this.state.radius}`)

    // wikipedia to geojson
    const {lng, lat} = coords
  	var sparql = `PREFIX schema: <http://schema.org/>
SELECT ?place ?placeLabel (SAMPLE(?sitelink) AS ?sl)  (SAMPLE(?img) AS ?image) ?coordinate_location ?dist ?instance_ofLabel WHERE {
  SERVICE wikibase:around {
    ?place wdt:P625 ?coordinate_location.
    bd:serviceParam wikibase:center "Point(${lng} ${lat})"^^geo:wktLiteral.
    bd:serviceParam wikibase:radius "${this.state.radius}".
    bd:serviceParam wikibase:distance ?dist.
  }
  SERVICE wikibase:label { bd:serviceParam wikibase:language "de,en,fr,es,ru". }
  OPTIONAL { ?sitelink schema:about ?place. }
  OPTIONAL { ?place wdt:P18 ?img. }
  OPTIONAL { ?place wdt:P31 ?instance_of. }
} GROUP BY?place ?placeLabel ?coordinate_location ?dist ?instance_ofLabel
ORDER BY ASC(?dist)
LIMIT 200`

  	wd.loadSPARQL ( sparql , (d) => {
      let geojson = {
        'type': 'FeatureCollection',
        'features':  d.results.bindings.map(item => {
		   	let v2 = item.coordinate_location.value.match ( /^Point\((\S+) (\S+)\)$/ )
        return {
          type: 'Feature',
          geometry: {type: 'Point', coordinates: [Number(v2[1]), Number(v2[2])]},
          properties: {
            name: item.placeLabel ? wd.itemFromBinding ( item.placeLabel ) : null,
            wikidata: item.place ? wd.itemFromBinding ( item.place ) : null,
            art: item.instance_ofLabel ? wd.itemFromBinding ( item.instance_ofLabel ) : null,
            distance: item.dist ? Number(wd.itemFromBinding ( item.dist )) : null,
          }
         }
        })
      }
      console.log(geojson)
      this.setState({ wikiGeoJson: geojson })
    })
  }

  render () {
    if (this.map && !_.isEmpty(this.state.wikiGeoJson)) {
      if (this.map.getLayer("wikiLayer")) {
        this.map.removeLayer("wikiLayer")
      }
      if (this.map.getSource("wikiLayer")) {
        this.map.removeSource("wikiLayer");
      }
      this.map.addLayer({
        "id": "wikiLayer",
        "type": "symbol",
        "source": {
          "type": "geojson",
          "data": this.state.wikiGeoJson
        },
        "layout": {
        'icon-image': "hospital-15",
        'text-field': '{name}',
        'text-offset': [0, 0.4],
        'text-anchor': 'top',
        "icon-allow-overlap": true
        }
      })
    }
    let placeholder = undefined
    console.log(this.state.coords)
    if (!_.isEmpty(this.state.coords)) {
      placeholder = format(this.state.coords)
    }

    return (
      <LayoutWithoutSidebar location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <article>
        <LatLonInput onChange={this.refocusMap} placeholder={placeholder}/>
        <div name="map">
          <div className='mapholder'>
            <div style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: '100%',
              height: '100%'
            }} ref={el => this.mapContainer = el} />
          </div>
        </div>
        </article>
     </LayoutWithoutSidebar>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`

class LatLonInput extends React.Component {
static defaultProps = {
    placeholder: "N 65.123 E 103.535"
  }

    constructor() {
    super()
    this.state = {
      value: '',
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault()
    console.log(event, event.target.value)
    let coords = parse(this.state.value)
    if (coords !== '') {
      this.props.onChange(coords)
    }
  };

  render() {
    let parsed = parse(this.state.value)
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
        <input
          type="search"
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.handleChange}
        /> {' '}
        <button type='submit'>🔍</button>
        </form>
        {format(parsed)}
      </div>
    );
  }
}
